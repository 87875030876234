import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/', //首页
        name: 'home',
        component: HomeView
    },
    {
        path: '/product', //智能产品
        name: 'product',
        component: () => import('../views/product.vue')
    },
    {
        path: '/solution', //解决方案
        name: 'solution',
        component: () => import('../views/Solution.vue')
    },
    {
        path: '/security', //数据安全
        name: 'security',
        component: () => import('../views/security.vue')
    },
    {
        path: '/help', //帮助中心
        name: 'help',
        component: () => import('../views/help.vue')
    },
    {
        path: '/about', //关于我们
        name: 'about',
        component: () => import('../views/AboutUs.vue')
    },

    
]

const router = new VueRouter({
    routes,

    // scrollBehavior(to, from, savedPosition) {
    //     // return 期望滚动到哪个的位置
    //     return { x: 0, y: 0 }
    // }

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router
