import Vue from 'vue';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

import { Carousel, CarouselItem, Form, FormItem, Row, Col, Input, Message, Menu, Submenu, MenuItem, MenuItemGroup } from 'element-ui';
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.prototype.$message = Message;
new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
