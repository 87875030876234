<template>
    <div id="app">
        <div class="heder">
            <div class="banxin">
                <div>
                    <div class="logo"></div>
                </div>

                <nav>
                    <el-menu router default-active="/" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
                        <el-submenu index="1">
                            <template slot="title">
                                <i class="el-icon-menu"></i>
                                <span slot="title">菜单</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index="/">首页</el-menu-item>
                                <el-menu-item index="/product">智能产品</el-menu-item>
                                <el-menu-item index="/solution">解决方案</el-menu-item>
                                <el-menu-item index="/security">数据安全</el-menu-item>
                                <el-menu-item index="/help">帮助中心</el-menu-item>
                                <el-menu-item index="/about">联系我们</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                    </el-menu>
                    <ul class="dh">
                        <li>
                            <router-link to="/" class="nava">首页</router-link>
                        </li>

                        <li>
                            <router-link to="/product" class="nava">智能产品</router-link>
                        </li>

                        <li>
                            <router-link to="/solution" class="nava">解决方案</router-link>
                        </li>

                        <li>
                            <router-link to="/security" class="nava">数据安全</router-link>
                        </li>

                        <li>
                            <router-link to="/help" class="nava">帮助中心</router-link>
                        </li>

                        <li>
                            <router-link to="/about" class="nava">联系我们</router-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <router-view />
        <!-- <Gotop></Gotop> -->
        <div class="footer">Copyright 2024 彬济科技（北京）有限公司 All rights reserved</div>
    </div>
</template>
<script>
// import Gotop from './views/components/gotop.vue';
export default {
    data() {
        return {
            isCollapse: true,
        };
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
    },
};
</script>
<style lang="less" scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-family: PingFangSC, PingFang SC;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    margin: 0 auto;
    background-color: #fafafa;
}

.heder {
    background-color: #ffffff;
    width: 100%;
    height: 62px;

    .banxin {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    @media screen and (max-width: 1199px) {
        .banxin {
            width: auto;
        }
    }

    .logo {
        width: 174px;
        height: 62px;
        background: url(./assets/logo@2x.png) no-repeat;
        background-size: 90%;
        background-position: center;
    }

    nav {
        display: flex;
        align-items: center;
        height: 62px;
        .dh {
            width: 600px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            li {
                width: 80px;
                line-height: 30px;
            }
        }
        .el-menu-vertical-demo {
            display: none;
        }
        @media screen and (max-width: 1199px) {
            .dh {
                display: none !important;
            }
            .el-menu-vertical-demo {
                display: block;
            }
        }
        @media screen and (max-width: 1199px) {
            .banxin {
                width: auto;
            }
        }
        .nava {
            display: block;
            font-weight: 500;
            font-size: 16px;
            color: #333;

            &.router-link-exact-active {
                border-bottom: 2px solid #036bfc;
                color: #036bfc;
            }
        }

        .nava:hover {
            color: #036bfc;
        }
    }
}
.footer {
    background-color: #fbfbfb;
    height: 50px;
    line-height: 50px;
    color: #666666;
}
@media screen and (max-width: 766px) {
    .footer {
        font-size: 12px;
    }
}
</style>
<style>
.el-menu-item {
    font-size: 18px;
    height: 50px;
    line-height: 50px;
}
</style>
