<template>
    <div>
        <div class="lunbo">
            <el-carousel :autoplay="true">
                <el-carousel-item>
                    <div class="tu1"></div>
                    <span>
                        <h3 class="small">数字时代的空间管理策略</h3>
                        <p>打造空间智能控制生态，实现人、空间与数据的三者合一，从而释放空间<br />的价值潜力，全面提升空间效率和用户体验</p>
                    </span>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="tu2"></div>
                    <span>
                        <h3 class="small">自主研发物联网智控主机</h3>
                        <p>新一代边缘计算智能控制主机，零代码编程，智联万物<br />的价值潜力，全面提升空间效率和用户体验</p>
                    </span>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="tu3"></div>
                    <span>
                        <h3 class="small">软硬件集中控制管理平台</h3>
                        <p>依托物联网、边缘计算等前沿技术和“云、边、端”的系统架构，构建企业<br />数字化底座 的价值潜力，全面提升空间效率和用户体验</p>
                    </span>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="c2">
            <div class="tu1">
                <h4>智慧空间</h4>
                <p>将办公空间所需的硬件设备<br />通过物联网模式进行智能控制</p>
            </div>
            <div class="tu2">
                <h4>智能体验</h4>
                <p>创造舒适、智能的办公空间<br />可以提高办公效率和用户体验</p>
            </div>
            <div class="tu3">
                <h4>数据洞察</h4>
                <p>充分挖掘与办公空间相关联<br />设备所产生数据的管理价值</p>
            </div>
        </div>
        <div class="c3">
            <div class="card">
                <div class="wenzi">
                    <h1 class="donghua1">物联网智能化软硬件卓越体验</h1>
                    <p>
                        通过自主研发新一代物联网智能化软件平台和硬 件，实现软硬件浑然一体的卓越体验，从初期部 署、后期管理，直至未来的系统拓展都会非常方
                        便，后期拓展及维护成本非常低，成本可控性强
                    </p>
                    <router-link to="/product" class="link">了解更多> </router-link>
                </div>
            </div>
        </div>
        <div class="c4">
            <div class="top">
                <h2>稳定、安全、高性能的智能控制服务</h2>
                <router-link to="/solution" class="link">了解更多> </router-link>
            </div>
            <div class="card">
                <div class="donghua2 tu1">
                    <p>面向企业办公、零售和教育行业的智能控制方案，为每一个空间提供基于全场景智能化的高效运营和使用体验</p>
                </div>
                <div class="donghua2 tu2">
                    <p>全局管理空间设备，集中控制灯光、空调、投影、商帘、音视频等系统</p>
                </div>
                <div class="donghua2 tu3">
                    <p>通过智能感应系统识别空 间状态，平衡建筑能耗，做到智能控制，“人来灯 开，人走灯关</p>
                </div>
                <div class="donghua2 tu4">
                    <p>远程监测设备状态及远程运行维护等一系列智慧管理，打造高效舒适的办公环境</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeView',
    data() {
        return {
            screenHeight: '',
        };
    },
    methods: {
        setStyle() {
            this.screenHeight = window.scrollY;
            console.log(this.screenHeight);
            if (this.screenHeight >= 200) {
                let d1 = document.querySelector('.donghua1');
                d1.style.right = 0 + 'px';
            }
            if (this.screenHeight >= 800) {
                let d2 = document.querySelectorAll('.donghua2');
                for (let i = 0; i < d2.length; i++) {
                    d2[i].style.opacity = 1;
                    d2[i].style.transform = 'scale(1)';
                }
            }
        },
    },
    created() {},
    mounted() {
        window.addEventListener('scroll', this.setStyle);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setStyle);
    },
    watch: {
        // screenWidth: function (val) {
        //     console.log(val)
        // }
    },
    components: {},
};
</script>
<style lang="less" scoped>
.c2 {
    background-color: #e2edff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    div {
        opacity: 0;
        width: 390px;
        height: 180px;
        border-radius: 12px;
        background-color: #e7792b;
        padding: 0px 30px;
        text-align: left;
        margin-right: 15px;
        animation-name: show;
        animation-fill-mode: forwards;
        animation-duration: 4s;
        h4 {
            font-size: 24px;
            margin-top: 44px;
        }
        p {
            margin-top: 11px;
        }
        &:last-child {
            margin-right: 0px;
        }
    }
    @media screen and (max-width: 766px) {
        div {
            width: 90%;
        }
    }
    .tu1 {
        background: url('../assets/首页/智慧空间.png') no-repeat;
        background-size: cover;
    }
    .tu2 {
        background: url('../assets/首页/智能体验.png');
        background-size: cover;
    }
    .tu3 {
        background: url('../assets/首页/数据洞察.png');
        background-size: cover;
    }
    @keyframes show {
        100% {
            opacity: 1;
        }
    }
}
@media screen and (max-width: 1199px) {
    .c2 {
        flex-direction: column;
        height: auto;
        div {
            margin-right: 0;
            margin: 10px 0;
        }
    }
}

.c3 {
    background-color: #c0e9fd;
    height: 660px;

    .card {
        height: 660px;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        position: relative;
        background: url('../assets/首页/banner4.png') no-repeat center center;
        background-size: cover;
        .wenzi {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            overflow: hidden;
            text-align: left;
            padding-left: 740px;
            padding-top: 208px;
            h1 {
                width: 420px;
                overflow: hidden;
                position: relative;
                right: -420px;
                transition: 2s;
                margin-bottom: 20px;
            }
            p {
                width: 420px;
                font-weight: 400;
                font-size: 20px;
                color: #333333;
                line-height: 34px;
                margin-bottom: 18px;
            }
            .link {
                font-weight: 500;
                font-size: 20px;
                color: #036bfc;
                line-height: 28px;
            }
        }
        @media screen and (max-width: 1199px) {
            .wenzi {
                padding-left: 0px;
                width: auto;
                h1 {
                    width: auto;
                }
                p {
                    width: auto;
                }
            }
        }
        @media screen and (max-width: 766px) {
            .wenzi {
                width: 95%;
                padding: 10px;
                padding-top: 420px;
                h1 {
                    width: auto;
                    font-size: 24px;
                }
                p {
                    width: auto;
                    font-size: 18px;
                }
            }
        }
    }
}
@media screen and (max-width: 766px) {
    .c3 {
        height: 700px;
        .card {
            height: 400px;
            background: url('../assets/首页/banner4.png') no-repeat 30% center;
            background-size: cover;
        }
    }
}
.c4 {
    background-color: #f3f8fe;
    height: 783px;
    padding-top: 80px;
    .top {
        h2 {
            line-height: 50px;
        }
        .link {
            display: block;
            margin-bottom: 20px;
            color: #036bfc;
            font-weight: 500;
            font-size: 20px;
        }
    }
    @media screen and (max-width: 766px) {
        .top {
           h2{
                font-size: 22px;
           }
        }
    }
    .card {
        display: flex;
        justify-content: center;
        align-items: center;
        div {
            width: 288px;
            height: 490px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            margin-right: 16px;
            padding: 0 36px;
            opacity: 0;
            transform: scale(0.5);
            transition: 1.5s;

            &:last-child {
                margin-right: 0px;
            }
        }
        .tu1 {
            background: url('../assets/首页/卡片01.png');
            background-size: 100%;
            padding: 0 48px;
            p {
                font-size: 24px;
                line-height: 40px;
                color: #fff;
                font-weight: 500;
                font-style: normal;
            }
        }
        .tu2 {
            background: url('../assets/首页/卡片02.png');
            background-size: 100%;
            p {
                font-weight: 400;
                font-size: 20px;
                color: #006798;
                line-height: 34px;
            }
        }
        .tu3 {
            background: url('../assets/首页/卡片03.png');
            background-size: 100%;
            p {
                font-weight: 400;
                font-size: 20px;
                color: #3d41aa;
                line-height: 34px;
            }
        }
        .tu4 {
            background: url('../assets/首页/卡片04.png');
            background-size: 100%;
            p {
                font-weight: 400;
                font-size: 20px;
                color: #0e5198;
                line-height: 34px;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .card {
            flex-direction: column;
            height: auto;
            div {
                margin-right: 0;
                margin: 10px;
            }
        }
    }
}
@media screen and (max-width: 1199px) {
    .c4 {
        height: auto;
    }
}
.lunbo {
    width: 100%;
    background-color: #b9d9ff;
    .banxin {
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }
    .el-carousel {
        margin: 0 auto;
        width: 100%;
        max-width: 1920px;
        // height: 480px;
        position: relative;

        .tu1 {
            height: 100%;
            background: url('../assets/首页/Banner01.png') no-repeat center center;
            background-size: cover;
        }
        .tu2 {
            height: 100%;
            background: url('../assets/首页/Banner02.png') no-repeat center center;
            background-size: cover;
        }
        .tu3 {
            height: 100%;
            background: url('../assets/首页/Banner03.png') no-repeat center center;
            background-size: cover;
        }

        span {
            display: block;
            margin: 0 auto;
            position: relative;
            width: 1200px;
            top: -310px;
            text-align: left;
            h3 {
                font-size: 44px;
                font-family: 'MyCustomFont' !important;
            }
            p {
                margin-top: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                line-height: 26px;
            }
        }
        @media screen and (max-width: 766px) {
            span {
                top: -240px;
                width: auto;
                padding: 10px;
                h3 {
                    font-size: 24px;
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .el-carousel {
            width: 100%;
            height: auto;
        }
    }
}
</style>
<style>
.el-carousel__container {
    height: 480px;
}
@media screen and (max-width: 1199px) {
    .el-carousel__container {
        height: 35vh;
    }
}
</style>
